/* src/styles/fonts.css */
@font-face {
  font-family: 'helvetica_neueregular';
  src: url('../assets/fonts/helveticaneue-01-webfont.woff2') format('woff2'),
    url('../assets/fonts/helveticaneue-01-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica_Neue_LT_GEO_55_Roman';
  src: url('../assets/fonts/HelveticaNeueLTGEO-55Roman.woff2') format('woff2'),
    url('../assets/fonts/HelveticaNeueLTGEO-55Roman.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* georgian */
html[lang='ge'] body {
  font-family: 'Helvetica_Neue_LT_GEO_55_Roman';
}

/* english */
html[lang='en'] body {
  font-family: 'helvetica_neueregular';
}
