/* full website scroll bar style */

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px transparent;  */
  /* background-color: #222B2E; */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #222b2e;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #000000;
}

/* full website scroll bar style */

/* selected text colors */
::selection {
  background-color: #7a7a7a;
  color: #fff;
}
/* smothe  */
body {
  scroll-behavior: smooth;
}
/* smothe  */

/* remove search input clear button */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

/* remove number input arrow buttons*/
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

html {
  /* min-height: 100vh;
  height: 100%; */
  background-color: #76838b;
}

/* hide admin text editor warning message */
.tox-notifications-container {
  display: none;
}

/* * {
  border: 1px solid red;
} */
